<template>
  <v-container>
    <div class="login">
      <v-form>
        <div>
          <v-img 
            class="mx-auto mb-8" width="50%" contain
            src="@/assets/images/onespring_logo.svg"
          ></v-img>
          
          <div class="login__content">
            <div class="input_background">
              <label for="account">
                <img :src="icon_account" />
              </label>
              <input
                type="text"
                ref="account"
                id="account"
                placeholder="아이디"
                v-model="account"
              />
            </div>

            <div class="input_background">
              <label for="password">
                <img :src="icon_password" />
              </label>
              <input
                type="password"
                ref="password"
                id="password"
                placeholder="비밀번호"
                v-model="password"
                maxlength="16"
              />
              <img :src="icon_eye" class="icon_eye" @click="togglePassword()" />
            </div>

            <button type="submit" class="button_login" @click.prevent="clickLogin()">
              로그인
            </button>
            
            <div style="display:flex; justify-content: center;">
              <v-btn outlined width="25%" 
                id="find_id"    
                href="/user/findid">
                아이디 찾기
              </v-btn>

              <span class="ml-2 mr-3" color="#666666"> | </span>
              
              <v-btn outlined width="25%" 
                id="find_password"
                href="/user/findpassword">
                비밀번호 찾기 
              </v-btn>
            </div>
          </div>
        </div>
      </v-form>

    </div>

  </v-container>
</template>

<script>
  import dateUtil from "@/utils/custom/dateProperty.js";
  export default {
    name: 'Login',

    data() {
      return {
        icon_account: require("@/assets/home/icon_account.svg"),
        icon_password: require("@/assets/home/icon_password.svg"),
        icon_eye: require("@/assets/home/icon_eye.svg"),

        account : "",
        password : "",
        showPassword: false
      };
    },
    mounted() {
      this.$refs.account.focus();
    },

    methods : {
      // ...mapMutations(['doLogin']),
      togglePassword() {
        switch (this.$refs.password.type) {
          case "password":
            this.$refs.password.type = "text";
            break;
          case "text":
            this.$refs.password.type = "password";
            break;
          default:
            this.$refs.password.type = "password";
            break;
        }
    },

      clickLogin() {
        // console.log("login submit");
        let account = this.account;
        let password = this.password;
        if (!account) {
          alert("아이디를 입력하세요.");
          this.$refs.account.focus();
          return;
        }
        if (!password) {
          alert("비밀번호를 입력하세요.");
          this.$refs.password.focus();
          return;
        }

        this.$dispatch("login", {
          session_id: this.getSessionId(account),
          account: account,
          password: password,
        }).then((res) => {
          if (res.result) {
            // 로그인 성공 시 환영메세지 팝업 노출
              // console.log("권한:",this.$getters.userInfo.grade);
            // alert(this.$getters.userName + "님 안녕하세요.");
            // this.$router.push("/monitoring/summary").catch((e) => {
            this.$router.push("/monitoring/appsummary2").catch((e) => {
              console.log(e.message);
            });
          } else {
            alert("ID , 비밀번호를 확인하세요. ");
            this.account = "";
            this.password = "";
            this.$refs.account.focus();
            // console.log(res);
          }
        }).catch( (err) => {  // 개별 API 오류 처리 예제
            console.log("LogIn 호출 오류",err);
            alert(err);
            this.$router.push("/user/login");
            }) ;
            // alert("님 안녕하세요.");

      },

      getSessionId(account) {
        let _sessionId = `${this.getNation()}/${this.getPageId()}/${this.getLocalTime()}/${account}////${this.getUtcTime()}`;
        // console.log(_sessionId);
        return _sessionId;
      },
      getNation() {
        let type = navigator.appName;
        let lang = "";
        let nation = "KOR"; // 기본값 한국
        if (type === "Netscape") {
          lang = navigator.language;
        } else {
          lang = navigator.userLanguage.substr(0, 2);
        }

        // TODO: 국가코드 정해달라고 해야됨
        switch (lang) {
          case "zh": // 중국
            nation = "CHN";
            break;
          case "en": // 미국
            nation = "ENG";
            break;
          default:
            break;
        }
        return nation;
      },
      getPageId() {
        // TODO: 화면아이디 지금 다 바뀌어서 다시 정해야될듯
        return "000010";
      },
      getLocalTime() {
        return dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss");
      },
      getUtcTime() {
        // 1. 현재 시간(Locale)
        const curr = new Date();
        // console.log("현재시간(Locale) : " + curr);

        // 2. UTC 시간 계산
        const utc = curr.getTime() + curr.getTimezoneOffset() * 60 * 1000;

        // TODO: 시간 형식 ISO 표준으로 하는게 맞을지 문서에 나온규격대로 짜르는게 맞을지?
        // return new Date(utc).toISOString();
        return dateUtil.format(new Date(utc), "yyyy-MM-dd HH:mm:ss");
      },


    }
  }
</script>

<style lang="scss" scoped>
*{
  margin:0;
  padding:0;
}
.login{
  display:flex;
  flex-direction: column;
  justify-content: center;
  width:100%;
  height:100vh;
  background: url(../../assets/barometer/login_bg.svg);
  background-size: cover;
  padding:10px;
}

.login__content{
  background-color:white;
  border-radius: 26px;
  padding:15px;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
}
.input_background {
  display: flex;
  align-items: center;
  width: 330px;
  height: 60px;
  border-radius: 50px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  margin: 0 auto 20px auto;
  // background-color: lawngreen;
}

input {
  position: relative;
  width: 300px;
  height: 27px;
  line-height: 27px;
  margin-left: 15px;
  color: black;
  font-size: 18px;
  text-align: left;
  outline: none;
  border: none;
}

label > img {
  margin-left: 20px;
  cursor: pointer;
}

.icon_eye {
  position: relative;
  left:-50px;
  cursor: pointer;
}

.button_login {
  position: relative;
  width: 300px;
  height: 60px;
  margin: 0 auto;
  margin-bottom: 15px;
  background-color: #88191a;
  border-radius: 30px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 60px;
  display: block;
  border : none;
  outline: none;
  cursor: pointer;
}

.login__content{
  display:flex;
  flex-direction: column;
  align-items: center;
  height:100%;
}

#find_id, #find_password{
  font-size:14px;
  letter-spacing: -0.74px;
  border:transparent;
  margin-top:-5px;
  color:#666666;
}

</style>